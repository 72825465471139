import React from 'react'
import "../Style/footerlol.css"

export default function Footerlol() {
  return (
    
    <footer>
    
      <div className='bootom'>
      °˖✧˚ʚ &nbsp;&nbsp;&nbsp;Back like I never left&nbsp;&nbsp;&nbsp;  ɞ˚✧˖°
      </div>

    </footer>
    
  )
}
