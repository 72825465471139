import React from 'react'

export default function About() {
  return (
    <div>
    <div className='PageHeader'><h1><span className='magic'>Im, Abhy</span></h1></div>
    
    

    
    <div className='aboutcontainer'>
    <div className='aboutclass'>
      <div className='abouttext'>
        <p>LLorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris fringilla nibh dui, sed venenatis felis bibendum efficitur. Cras sed consectetur nisl. Cras tellus nisl, vulputate eu enim at, pellentesque tempor orci. Maecenas in velit quis eros rutrum viverra vel in diam. Pellentesque magna magna, auctor ac tortor a, congue pellentesque nunc. Maecenas vitae nunc ante. Nullam eu elementum orci, quis venenatis nulla. Aenean elementum elit in quam pretium tempus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vivamus urna nisl, eleifend vel ligula accumsan, molestie gravida ligula. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec viverra semper odio eget maximus. Nullam vehicula neque id imperdiet viverra. Curabitur ex lacus, tincidunt eget augue sit amet, sodales convallis enim.

Proin pretium elementum ipsum non consectetur. Duis eros diam, sollicitudin et elit vehicula, vestibulum egestas orci. Nullam sollicitudin pulvinar tellus, nec dapibus mi ultricies a. Quisque eu sapien id augue accumsan sagittis eget vel lectus. Etiam nec justo consectetur, pulvinar urna eu, dictum nulla. Proin et erat fermentum, rhoncus ante ut, accumsan metus. Vestibulum consequat molestie accumsan.

Mauris eu convallis tortor, sit amet malesuada orci. Duis urna est, sollicitudin vitae facilisis eget, placerat et elit. Vivamus lobortis est at risus lobortis, eget tempor lorem bibendum. Etiam faucibus elit varius, malesuada leo eu, ornare erat. Nunc convallis lacus eget metus tristique consectetur. Mauris ac nisi eu nunc feugiat volutpat. Ut dui elit, convallis sed massa eu, finibus pretium tortor.

Suspendisse ante lectus, euismod vitae purus aliquam, rutrum tincidunt libero. Praesent elementum molestie est, sed ultricies felis consequat eu. Sed finibus orci vel ligula rutrum lobortis. Mauris a risus in purus tempor iaculis at vel sem. Curabitur interdum risus metus, ut pretium erat elementum eget. Integer a odio at nibh pharetra congue. Vivamus a eleifend nunc. Morbi venenatis arcu sed massa pellentesque aliquet. Praesent in auctor ipsum. Cras ut purus non neque dapibus volutpat sed non risus.

Mauris sit amet interdum felis. Nulla consequat placerat nisl non sodales. Quisque scelerisque eu erat id ornare. Suspendisse dapibus quis odio et ultricies. Mauris eget erat eget enim aliquam rutrum vel id libero. Proin euismod suscipit fermentum. Fusce sapien orci, blandit nec placerat non, sodales nec augue. Maecenas id euismod est. Mauris ornare nunc felis, sed ultrices massa suscipit sed. Curabitur et risus consectetur, accumsan felis non, porta sem. Pellentesque porttitor ante mi, ut lobortis leo mollis a. Fusce accumsan orci urna, ac consectetur ante sagittis eu. Nunc nec aliquam odio.</p>
      </div>
    
    </div>


    </div>

    </div>
    
  )
}
